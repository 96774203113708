import request from '@/utils/request'


// 查询用户邀请记录列表
export function listInviteRecord(query) {
  return request({
    url: '/user/user-invite-record/list',
    method: 'get',
    params: query
  })
}

// 查询用户邀请记录分页
export function pageInviteRecord(query) {
  return request({
    url: '/user/user-invite-record/page',
    method: 'get',
    params: query
  })
}

// 查询用户邀请记录详细
export function getInviteRecord(data) {
  return request({
    url: '/user/user-invite-record/detail',
    method: 'get',
    params: data
  })
}

// 新增用户邀请记录
export function addInviteRecord(data) {
  return request({
    url: '/user/user-invite-record/add',
    method: 'post',
    data: data
  })
}

// 修改用户邀请记录
export function updateInviteRecord(data) {
  return request({
    url: '/user/user-invite-record/edit',
    method: 'post',
    data: data
  })
}

// 删除用户邀请记录
export function delInviteRecord(data) {
  return request({
    url: '/user/user-invite-record/delete',
    method: 'post',
    data: data
  })
}
